import { useLazyQuery } from '@apollo/client';
import classes from './UpcomingClasses.module.scss';
import { SupplierClassesQuery } from 'graphql/queries';
import { Card, Flex, LoadingOverlay, Spoiler, Text, useMantineTheme } from '@mantine/core';
import { Calendar } from '@mantine/dates';
import { useEffect, useReducer } from 'react';
import dayjs from 'dayjs';
import { showErrorMessage } from 'utils/showErrorMessage/showErrorMessage';
import { useMediaQuery } from '@mantine/hooks';
import Link from 'next/link';
import { Actions, trackAction } from 'utils/amplitude';
import { UsersThree } from '@phosphor-icons/react';

interface IUpcomingClassesProps {
  token: string;
  supplierId: string;
}

export enum BookingTypesEnum {
  'INSTANT' = 'INSTANT',
  'SUBSCRIPTION' = 'SUBSCRIPTION',
}

interface ISessionsForDate {
  id: string;
  name: string;
  location: string;
  date: string;
  time: string;
  capacity: number;
  bookings: number;
  bookingType: BookingTypesEnum;
}

export interface ISupplierUpcomingClassesData {
  supplierClasses: {
    sessionsForDate: ISessionsForDate[];
    sessionDates: string[];
  };
}

export enum ActionTypes {
  DATA_LOADED = 'data_loaded',
  DATE_CHANGED = 'date_changed',
  SHOW_OVERLAY = 'show_overlay',
}

export type Action =
  | { type: ActionTypes.DATA_LOADED; payload: ISupplierUpcomingClassesData }
  | { type: ActionTypes.DATE_CHANGED; payload: Date }
  | { type: ActionTypes.SHOW_OVERLAY; payload: boolean };

export interface IUpcomingClassesState {
  forDate: dayjs.Dayjs;
  sessionsForDate: ISessionsForDate[];
  upcomingSessionDates: string[];
  showLoadingOverlay: boolean;
}

const initialState: IUpcomingClassesState = {
  forDate: dayjs(),
  sessionsForDate: [] as ISessionsForDate[],
  upcomingSessionDates: [] as string[],
  showLoadingOverlay: false,
};

// The reducer function
export function upcomingClassesReducer(
  state: IUpcomingClassesState,
  action: Action,
): IUpcomingClassesState {
  switch (action.type) {
    case ActionTypes.DATA_LOADED:
      return {
        ...state,
        sessionsForDate: action.payload.supplierClasses?.sessionsForDate || [],
        upcomingSessionDates: action.payload.supplierClasses?.sessionDates || [],
      };
    case ActionTypes.DATE_CHANGED:
      trackAction(Actions.DASH_CALENDAR);
      return {
        ...state,
        forDate: dayjs(action.payload),
      };
    case ActionTypes.SHOW_OVERLAY:
      return {
        ...state,
        showLoadingOverlay: action.payload,
      };
    default:
      return state;
  }
}

type IClassDetailsProps = {
  session: ISessionsForDate;
  supplierId: string;
  forDate: dayjs.Dayjs;
  color: string;
};

export const ClassDetails: React.FC<IClassDetailsProps> = ({
  session,
  supplierId,
  forDate,
  color,
}) => {
  const isSubscription = session.bookingType === BookingTypesEnum.SUBSCRIPTION;
  const href =
    session.bookings > 0
      ? `/all-bookings?supplierId=${supplierId}&sessionId=${session.id}&sessionDate=${forDate}&time=${session.time}&isSubscription=${isSubscription}`
      : `/all-bookings?supplierId=${supplierId}&isSubscription=${isSubscription}`;
  return (
    <Link
      passHref
      href={href}
      className={classes.bookingsAndCapacity}
      onClick={() => {
        trackAction(Actions.DASH_UPCOMING_CAPACITY);
      }}
    >
      <UsersThree
        size={22}
        className={classes.usersIcon}
        color={color}
        data-testid="users-three-icon"
      />
      <span>
        {session.bookings} / {session.capacity}
      </span>
    </Link>
  );
};

const UpcomingClasses: React.FC<IUpcomingClassesProps> = ({
  token,
  supplierId,
}: IUpcomingClassesProps) => {
  const theme = useMantineTheme();

  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`, true);

  const [state, dispatch] = useReducer(upcomingClassesReducer, initialState);

  const [getData, { loading }] = useLazyQuery<ISupplierUpcomingClassesData>(SupplierClassesQuery, {
    context: {
      headers: {
        Authorization: `${token}`,
      },
    },
    onError: (error) => {
      console.log(error);
      showErrorMessage(error);
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    onCompleted: (data) => {
      dispatch({ type: ActionTypes.DATA_LOADED, payload: data });
    },
  });

  useEffect(() => {
    if (token) {
      getData({
        variables: {
          supplierId,
          forDate: state.forDate.format('YYYY-MM-DD'),
        },
      });
    }
  }, [state.forDate, supplierId, token, getData]);

  // if fetching data takes more than a second, show a loading overlay
  const delayBeforeShowingLoadingOverlay = 1000;

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;
    if (loading && !state.showLoadingOverlay) {
      // Set a delay before showing the overlay
      timer = setTimeout(() => {
        dispatch({ type: ActionTypes.SHOW_OVERLAY, payload: true });
      }, delayBeforeShowingLoadingOverlay);
    } else if (!loading && state.showLoadingOverlay) {
      // Hide the overlay immediately when not loading
      dispatch({ type: ActionTypes.SHOW_OVERLAY, payload: false });
    }
    return () => clearTimeout(timer);
  }, [loading, state.showLoadingOverlay]);

  const minMaxProps = {
    minDate: new Date(state.upcomingSessionDates[0]),
    maxDate: new Date(state.upcomingSessionDates[state.upcomingSessionDates.length - 1]),
  };

  const getSessionDatesByMonth = state.upcomingSessionDates.map((session) => session);

  const isDateExcluded = (d: Date): boolean => {
    if (getSessionDatesByMonth?.includes(dayjs(d).format('YYYY-MM-DD'))) {
      return false;
    }

    return true;
  };

  const getDayClass = (date: Date) => {
    const dayjsDate = dayjs(date);
    const dayjsStart = dayjs(state.upcomingSessionDates[0]);
    const dayjsEnd = dayjs(state.upcomingSessionDates[state.upcomingSessionDates.length - 1]);

    if (isDateExcluded(date)) {
      return '';
    }

    if (
      dayjsDate.isSame(dayjsStart, 'day') ||
      dayjsDate.isSame(dayjsEnd, 'day') ||
      dayjsDate.isBetween(dayjsStart, dayjsEnd)
    ) {
      return classes.upcomingDate;
    }

    return '';
  };

  const onSetForDate = (date: Date) => {
    dispatch({ type: ActionTypes.DATE_CHANGED, payload: date });
  };

  const isSelected = (date: Date): boolean => {
    return state.forDate.isSame(date, 'date');
  };

  const makeCardKey = (session: ISessionsForDate, index: number) => {
    // can't use session.id as it could be null if its a subscription and no tickets have been sold -> using index
    return `upcoming-classes-${session.name}-${session.date}-${session.time}-${
      session?.id || index
    }`;
  };

  if (state.upcomingSessionDates.length === 0) {
    return <Text>No upcoming classes</Text>;
  }

  return (
    <div>
      <Flex justify="center">
        <Calendar
          onPreviousMonth={() => trackAction(Actions.DASH_CALENDAR)}
          onNextMonth={() => trackAction(Actions.DASH_CALENDAR)}
          onPreviousYear={() => trackAction(Actions.DASH_CALENDAR)}
          onNextYear={() => trackAction(Actions.DASH_CALENDAR)}
          data-testid="upcoming-classes-calendar"
          classNames={{
            calendarHeader: classes.calendarHeader,
            calendarHeaderControl: classes.calendarPagination,
            calendarHeaderLevel: classes.calendarMonth,
            weekday: classes.weekday,
            day: classes.dayCell,
          }}
          getDayProps={(date: Date) => ({
            className: getDayClass(date),
            selected: isSelected(date),
            onClick: () => onSetForDate(date),
          })}
          ariaLabels={{
            nextMonth: 'next-month-button',
            previousMonth: 'previous-month-button',
          }}
          mb="md"
          px={0}
          size={isMobile ? 'sm' : 'md'}
          hideOutsideDates
          withCellSpacing={false}
          {...minMaxProps}
        />
      </Flex>
      <Text
        size="lg"
        ml="md"
        mt="lg"
        mb="sm"
        style={{
          fontWeight: 700,
        }}
      >
        You have {state.sessionsForDate.length} classes
      </Text>
      <Spoiler
        maxHeight={208}
        showLabel="View more"
        hideLabel="View less"
        classNames={{
          control: classes.spoilerControl,
        }}
      >
        {state.sessionsForDate.map((session, index) => {
          return (
            <Card key={makeCardKey(session, index)} py="xs">
              <Flex direction="row" align="center" justify="space-between">
                <Flex direction="column">
                  <Text
                    className={classes.sessionName}
                    style={{
                      fontWeight: 600,
                    }}
                  >
                    {session.name}
                  </Text>
                  <Text
                    mt={2}
                    size="xs"
                    c={theme.colors.gray[6]}
                    style={{
                      fontWeight: 600,
                    }}
                  >
                    {session.date}, {session.time}
                  </Text>
                </Flex>
                <ClassDetails
                  session={session}
                  supplierId={supplierId}
                  forDate={state.forDate}
                  color={theme.colors.blue[8]}
                />
              </Flex>
            </Card>
          );
        })}
      </Spoiler>
      <Flex justify="center">
        <Link
          href={`/session-registers?supplierId=${supplierId}`}
          passHref
          className={classes.goToRegister}
          onClick={() => trackAction(Actions.DASH_REGISTER)}
        >
          Go to register
        </Link>
      </Flex>
      <LoadingOverlay visible={state.showLoadingOverlay} />
    </div>
  );
};

export default UpcomingClasses;
