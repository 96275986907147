import { createContext, FC, ReactNode, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { ActivityType } from 'interfaces';
import { ClonedActivityContextType } from 'types';
import {
  getFilteredBlocks,
  restoreSessionTimeslots,
} from 'hooks/useManageActivityForm/useManageActivityForm.utils';
import { BlockBookingsTypeEnum } from 'enums';

export const ClonedActivityContext = createContext<ClonedActivityContextType | null>(null);

interface ClonedActivityContextProviderProps {
  children: ReactNode;
}

export const ClonedActivityContextProvider: FC<ClonedActivityContextProviderProps> = (props) => {
  const [clonedActivity, setStoredClonedActivity] = useState<ActivityType | null>(null);

  const resetClonedActivity = () => {
    setStoredClonedActivity(null);
  };

  const setClonedActivity = (activity: ActivityType) => {
    // We need to maintain the relationship of tickets, addons, blocks and sessions to their class whilst generating new IDs.
    const newClasses = activity.classes.map((classItem) => {
      const newId = uuidv4();
      const newSessions = classItem.sessions.map((session) => {
        return {
          ...session,
          id: uuidv4(),
          classId: newId,
          isNew: true,
        };
      });
      const classTimeSlots = restoreSessionTimeslots(newSessions);
      const newBlocks = getFilteredBlocks(
        classTimeSlots,
        newSessions,
        activity.blockSubtype || BlockBookingsTypeEnum.ALL_DAYS,
        newId,
        true,
      );
      return {
        ...classItem,
        isNew: true,
        id: newId,
        tickets: classItem.tickets.map((ticket) => {
          return {
            ...ticket,
            classIds: ticket.classIds?.map((classId) => {
              if (classId === classItem.id) {
                return newId;
              }
              return classId;
            }),
          };
        }),
        addons: classItem.addons.map((addon) => {
          return {
            ...addon,
            classIds: addon.classIds?.map((classId) => {
              if (classId === classItem.id) {
                return newId;
              }
              return classId;
            }),
          };
        }),
        blocks: newBlocks,
        sessions: newSessions,
      };
    });
    const newClonedActivity: ActivityType = {
      ...activity,
      classes: newClasses,
    };

    setStoredClonedActivity(newClonedActivity);
  };

  return (
    <ClonedActivityContext.Provider
      value={{ clonedActivity, setClonedActivity, resetClonedActivity }}
    >
      {props.children}
    </ClonedActivityContext.Provider>
  );
};
