import { gql } from '@apollo/client';

const SupplierMemberships = gql`
  query SupplierMemberships($supplierId: UUID!) {
    marketplaceSupplierMemberships(supplierId: $supplierId) {
      id
      isSuperadmin
      adminAccess {
        id
        name
        niceName
      }
      supplierAdmin {
        id
        emailAddress
        fullName
        cognitoStatus
      }
    }
  }
`;

export default SupplierMemberships;
