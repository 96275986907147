import { Dispatch, FC, Fragment, SetStateAction, useEffect, useMemo, useState } from 'react';
import classes from './ActivityScheduleSummary.module.scss';
import {
  ActionIcon,
  Anchor,
  Button,
  Center,
  Divider,
  Drawer,
  Flex,
  Group,
  Menu,
  Notification,
  Space,
  Stack,
  Switch,
  Text,
  useMantineTheme,
  useCombobox,
} from '@mantine/core';
import { ActivityTypeIcon } from 'components/pages/ManageWaitlist/components/ActivityTypeIcon';
import {
  CalendarBlank,
  ListPlus,
  MapPin,
  Warning,
  X,
  ClockClockwise,
  VideoCamera,
  Info,
  PencilSimple,
  Eye,
  EnvelopeSimple,
  QrCode,
  Copy,
  Trash,
  DotsThreeOutlineVertical,
  CaretRight,
} from '@phosphor-icons/react';
import {
  buildFullAddressFromLocation,
  getAbbreviatedDaysString,
  getDates,
  getWeekdayLabel,
  handleCapacityClick,
  handleDownloadQRCode,
  handleEditClick,
} from '../../ScheduledActivities.utils';
import dayjs from 'dayjs';
import { ActivityBlock, ActivitySession, ActivitySubscriptionSchedule } from 'types';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useClipboard, useMediaQuery } from '@mantine/hooks';
import { showErrorMessage } from 'utils/showErrorMessage/showErrorMessage';
import { LoadingCards } from 'components/LoadingCards';
import { BookingStatus } from '../TableView/BookingStatus/BookingStatus';
import ActivitySessionsOverview from 'graphql/queries/activity-sessions-overview';
import { Actions, trackAction } from 'utils/amplitude';
import { ActivityType } from 'interfaces';
import {
  ActivityBookingTypeEnum,
  ActivityTypeEnum,
  BlockBookingsTypeEnum,
  ClassesDateFilterEnum,
  DaysOfWeekEnum,
} from 'enums';
import { PebblePopover } from 'components/common/PebblePopover/PebblePopover';
import classNames from 'classnames';
import { EnableRegisterCheckinCheckoutMode } from 'graphql/mutations';
import { Capacity, Clone } from '@icons';
import { useRouter } from 'next/router';
import { getQuickViewLink } from 'utils/getQuickViewLink/getQuickViewLink';
import { DeleteModal } from '../DeleteModal';
import ClassFilters from '../ClassFilters/ClassFilters';

interface IActivityScheduleSummaryProps {
  openedActivityScheduleSummary: boolean;
  close(): void;
  selectedActivity: ActivityType | null;
  setSelectedActivity?(val: ActivityType | null): void;
  token: string;
  supplierId: string;
  attendanceOverviewId?: string;
  onClone(clonedActivityId: string | undefined): void;
  onInviteAttendee(mobileSelectedActivityId: string | undefined): void;
  setActivityToDelete: Dispatch<SetStateAction<string | null>>;
  activityToDelete: string | null;
  setShowDeleteModal: Dispatch<SetStateAction<boolean>>;
  showDeleteModal: boolean;
  onDelete(): void;
  setClassesDateFilter: (val: ClassesDateFilterEnum) => void;
  classesDateFilter: ClassesDateFilterEnum;
}
interface ListElementProps {
  children: JSX.Element[];
  sessionId?: string;
  sessionDate?: string;
  onClickHandler?: () => void;
  totalBookings?: number;
  totalCapacity?: number;
  totalSpotsFilledRatio?: number;
  requestedRegisterParam?: string;
}

const ActivityScheduleSummary: FC<IActivityScheduleSummaryProps> = ({
  openedActivityScheduleSummary,
  close,
  selectedActivity,
  setSelectedActivity,
  token,
  supplierId,
  attendanceOverviewId = '',
  onClone,
  onInviteAttendee,
  showDeleteModal,
  setShowDeleteModal,
  activityToDelete,
  setActivityToDelete,
  onDelete,
  setClassesDateFilter,
  classesDateFilter,
}) => {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`, true);
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });

  const isIndividual = selectedActivity?.activityType === ActivityTypeEnum.ONE_OFF;
  const isSubscription = selectedActivity?.bookingType === ActivityBookingTypeEnum.SUBSCRIPTION;

  const router = useRouter();
  const clipboard = useClipboard({ timeout: 1000 });

  const getSelectedClass = () => {
    return selectedActivity?.classes?.length === 1 ? selectedActivity?.classes[0].id : null;
  };

  const [showWarningNotification, setShowWarningNotification] = useState<boolean>(!isIndividual);
  const [registerCheckinCheckoutLoading, setRegisterCheckinCheckoutLoading] = useState(false);
  const [selectedClass, setSelectedClass] = useState<string | null>(getSelectedClass());

  useEffect(() => {
    setSelectedClass(
      selectedActivity?.classes?.length === 1 ? selectedActivity?.classes[0].id : null,
    );
  }, [selectedActivity?.classes]);

  // TODO: D/W Stevie can be optimised later, removing this affect the checkin/checkout toggle but lots of shared info from ManageActivityQuery on scheduled activities page
  const [getData, { loading, data }] = useLazyQuery(ActivitySessionsOverview, {
    notifyOnNetworkStatusChange: true,
    context: {
      headers: {
        Authorization: `${token}`,
      },
    },
    fetchPolicy: 'cache-and-network', // This is needed to make sure, filters are not persisted between queries
    onError: (error) => {
      showErrorMessage(error);
    },
  });

  useEffect(() => {
    if (selectedActivity) {
      getData({
        variables: {
          supplierId,
          activityId: selectedActivity.id,
        },
      });
    }
  }, [selectedActivity, getData, supplierId]);

  const showWaitlistBadge = useMemo(
    () =>
      Boolean(
        (selectedActivity?.waitlistRequestCount && selectedActivity?.waitlistRequestCount > 0) ||
          (data?.activitiesBySupplier?.activities[0] &&
            data?.activitiesBySupplier?.activities[0].waitlistRequestCount > 0),
      ),
    [selectedActivity, data?.activitiesBySupplier?.activities],
  );
  const checkInOutTimestamps = useMemo(
    () =>
      Boolean(
        data?.activitiesBySupplier?.activities[0]?.enableRegisterCheckinCheckoutMode ||
          selectedActivity?.enableRegisterCheckinCheckoutMode,
      ),
    [selectedActivity, data?.activitiesBySupplier],
  );

  const [enableRegisterCheckinCheckout] = useMutation(EnableRegisterCheckinCheckoutMode, {
    onError: (error) => {
      console.log(error);
    },
    onCompleted: () => {
      setRegisterCheckinCheckoutLoading(false);
    },
  });

  const handleRegisterCheckInOutSwitch = async (checkinCheckoutMode: boolean) => {
    setRegisterCheckinCheckoutLoading(true);
    const input = {
      activityId: selectedActivity?.id,
      enableRegisterCheckinCheckoutMode: checkinCheckoutMode,
    };

    await enableRegisterCheckinCheckout({
      variables: {
        input,
      },
      ...(token && {
        context: {
          headers: {
            Authorization: `${token}`,
          },
        },
      }),
    });
  };

  const getPastSessions = (sessions: ActivitySession[]) => {
    const today = dayjs();
    return sessions.filter((session) => {
      const sessionDate = dayjs(session.date).startOf('day');
      return !sessionDate.isSame(today, 'day') && sessionDate.isBefore(today, 'day');
    });
  };

  const getUpcomingSessions = (sessions: ActivitySession[]) => {
    const today = dayjs();
    return sessions.filter((session) => {
      const sessionDate = dayjs(session.date).startOf('day');
      return sessionDate.isSame(today, 'day') || sessionDate.isAfter(today, 'day');
    });
  };

  const ListElement = ({
    children,
    sessionId,
    sessionDate,
    totalBookings,
    totalCapacity,
    totalSpotsFilledRatio,
    requestedRegisterParam,
  }: ListElementProps) => {
    const getAnchorLink = (): string => {
      if (isMobile || isIndividual) {
        return `/session-registers?supplierId=${supplierId}&sessionId=${sessionId}&sessionDate=${sessionDate}`;
      } else {
        return `/attendance-overview?supplierId=${supplierId}&${requestedRegisterParam}`;
      }
    };

    const shouldOpenInNewTab = useMemo(() => {
      if (isMobile || isIndividual || router.pathname !== '/scheduled-activities') {
        return false;
      }
      return true;
    }, []);

    return (
      <Fragment key={sessionId}>
        <Anchor
          p={12}
          className={classNames(classes.sessionItem, {
            [classes.disabled]:
              sessionId === attendanceOverviewId || registerCheckinCheckoutLoading,
          })}
          component={sessionId === attendanceOverviewId ? 'div' : 'a'}
          href={getAnchorLink()}
          target={shouldOpenInNewTab ? '_blank' : '_self'}
          underline="never"
          onClick={() => {
            if (sessionId === attendanceOverviewId) return null;
            if (!isMobile && !isIndividual && requestedRegisterParam) {
              trackAction(Actions.SPOTS_FILLED, { redirect: 'Attendance overview' });
            } else {
              trackAction(Actions.SPOTS_FILLED, { redirect: 'Check-in register' });
            }
          }}
          data-testid="session-item"
        >
          <Stack gap={8} align="start">
            {children}
          </Stack>
          <Flex align="center">
            <Stack gap={0} align="center" className={classes.capacityButton}>
              <BookingStatus
                totalBookings={totalBookings || 0}
                totalCapacity={totalCapacity || 0}
                withTooltip={false}
                borderedDot={'white'}
                totalSpotsFilledRatio={totalSpotsFilledRatio}
              />
              <Text c={theme.colors.blue[8]} size="xs">
                Spots filled
              </Text>
            </Stack>
            <CaretRight size={18} />
          </Flex>
        </Anchor>
        <Center>
          <Divider w={'95%'} />
        </Center>
      </Fragment>
    );
  };

  const filteredClasses = useMemo(() => {
    const foundClass = selectedActivity?.classes?.find((activityClass) => {
      return activityClass.id === selectedClass;
    });

    if (!foundClass) return;

    const today = dayjs();

    if (classesDateFilter === ClassesDateFilterEnum.ALL) {
      return foundClass;
    }

    if (classesDateFilter === ClassesDateFilterEnum.UPCOMING) {
      const upcomingSessions = getUpcomingSessions(foundClass.sessions);
      const upcomingBlocks = foundClass.blocks.filter(
        (block) =>
          dayjs(block.maxDate).isSame(today, 'day') || dayjs(block.maxDate).isAfter(today, 'day'),
      );

      return {
        ...foundClass,
        sessions: upcomingSessions,
        blocks: upcomingBlocks,
      };
    }

    if (classesDateFilter === ClassesDateFilterEnum.PAST) {
      const pastSessions = getPastSessions(foundClass.sessions);
      const pastBlocks = foundClass.blocks.filter(
        (block) =>
          !dayjs(block.maxDate).isSame(today, 'day') && dayjs(block.maxDate).isBefore(today, 'day'),
      );

      return {
        ...foundClass,
        sessions: pastSessions,
        blocks: pastBlocks,
      };
    }
  }, [selectedClass, selectedActivity?.classes, classesDateFilter]);

  const renderSessionsOverview = () => {
    const listData = data?.activitiesBySupplier?.activities[0];

    if (!listData) {
      return;
    }

    const { activityType, blockSubtype } = listData;
    switch (activityType) {
      case ActivityTypeEnum.ONE_OFF:
        return filteredClasses?.sessions.map((session: ActivitySession) => {
          return (
            <ListElement
              key={session.id}
              sessionId={session.id}
              sessionDate={session.date}
              totalBookings={session.bookingCount}
              totalCapacity={session.capacity}
            >
              <Text fw={700}>{dayjs(session.date).format('dddd')}</Text>
              <Text>{dayjs(session.date).format('DD MMM YYYY')}</Text>
              <Text>{`${session.startTime} - ${session.endTime}`}</Text>
            </ListElement>
          );
        });
      case ActivityTypeEnum.SUBSCRIPTION:
        return listData.schedules.map((schedule: ActivitySubscriptionSchedule) => {
          return (
            <ListElement
              key={schedule.id}
              sessionId={schedule.id}
              totalBookings={schedule.activeSubs}
              totalCapacity={schedule.capacity}
              requestedRegisterParam={`registerScheduleId=${schedule.id}`}
            >
              <Text fw={700}>{`${getWeekdayLabel(schedule.dayOfTheWeek)}s`}</Text>
              <Text>{`Next: ${dayjs(schedule.nextSessionDate).format('DD MMM YYYY')}`}</Text>
              <Text>{`${schedule.startTime} - ${schedule.endTime}`}</Text>
            </ListElement>
          );
        });
      default:
        return filteredClasses?.blocks.map((block: ActivityBlock) => {
          const weekdays = Array.from(
            new Set(
              block.sessions.map((session) => {
                if (typeof session === 'string') {
                  return;
                } else {
                  return session?.dayOfTheWeek;
                }
              }),
            ),
          ) as DaysOfWeekEnum[];

          return (
            <ListElement
              key={block.id}
              sessionId={block.id}
              sessionDate={block.minDate}
              totalSpotsFilledRatio={block.totalSpotsFilledRatio}
              requestedRegisterParam={`registerBlockId=${block.id}&classId=${selectedClass}`}
            >
              <Text fw={700} className={classes.weekdays}>
                {blockSubtype === BlockBookingsTypeEnum.ALL_DAYS
                  ? getAbbreviatedDaysString(weekdays)
                  : `${getWeekdayLabel(weekdays?.[0])}s`}
              </Text>
              <Text>{`${dayjs(block.minDate).format('DD MMM YYYY')} - ${dayjs(block.maxDate).format(
                'DD MMM YYYY',
              )}`}</Text>
              <Text>{`${block.coreStartTime} - ${block.coreEndTime}`}</Text>
              {block.timesVary ? (
                <Text fw={700} className={classes.timesVaryBadge}>
                  Times vary <ClockClockwise size={18} />
                </Text>
              ) : (
                <></>
              )}
            </ListElement>
          );
        });
    }
  };

  return (
    <Drawer
      title={
        <>
          <Group align="flex-start" className={classes.activityName}>
            <ActivityTypeIcon type={selectedActivity?.activityType} />
            <Text fw={700} size={'20px'} maw={'70%'}>
              {selectedActivity?.name}
            </Text>
          </Group>
          <Menu
            shadow="md"
            width={180}
            classNames={{ dropdown: classes.menuDropdown, item: classes.menuItem }}
          >
            <div>
              <Menu.Target>
                <ActionIcon
                  variant="subtle"
                  onClick={() => trackAction(Actions.KEBAB)}
                  title={`Options for ${selectedActivity?.name}`}
                >
                  <DotsThreeOutlineVertical size={28} weight="fill" color={theme.colors.gray[6]} />
                </ActionIcon>
              </Menu.Target>
            </div>
            <Menu.Dropdown>
              <Menu.Item
                leftSection={<Capacity size={24} />}
                onClick={() => {
                  handleCapacityClick(router, selectedActivity?.id);
                  trackAction(Actions.SCHEDULED_SESSIONS);
                }}
              >
                Capacity
              </Menu.Item>
              <Menu.Item
                leftSection={<Clone size={24} />}
                onClick={() => {
                  onClone(selectedActivity?.id);
                  trackAction(Actions.SCHEDULED_CLONE);
                }}
              >
                Clone
              </Menu.Item>

              <Menu.Item
                leftSection={<PencilSimple weight="fill" size={22} />}
                onClick={() => {
                  handleEditClick(router, selectedActivity?.id);
                  trackAction(Actions.SCHEDULED_EDIT);
                }}
              >
                Edit
              </Menu.Item>

              <Menu.Item
                leftSection={<Eye weight="bold" size={24} />}
                component="a"
                href={getQuickViewLink(selectedActivity)}
                target="_blank"
                onClick={() => {
                  trackAction(Actions.SCHEDULED_VIEW);
                }}
              >
                View listing
              </Menu.Item>

              <>
                <Menu.Item
                  leftSection={<ListPlus size={24} color="white" />}
                  component="a"
                  href={`/manage-waitlist/${selectedActivity?.id}?supplierId=${router.query.supplierId}`}
                  onClick={() => trackAction(Actions.VIEW_WAITLIST)}
                >
                  Waitlist
                </Menu.Item>
              </>

              <Menu.Item
                leftSection={<EnvelopeSimple size={24} weight="fill" />}
                onClick={() => {
                  onInviteAttendee(selectedActivity?.id);
                  close();
                  trackAction(Actions.ENROL_CUSTOMERS_CTA);
                }}
              >
                Invite customers
              </Menu.Item>

              <Menu.Item
                leftSection={<QrCode weight="fill" size={24} className={classes.menuIcon} />}
                onClick={() => handleDownloadQRCode(selectedActivity)}
              >
                Get QR code
              </Menu.Item>

              <Menu.Item
                leftSection={<Copy size={24} weight="bold" className={classes.menuIcon} />}
                onClick={() => clipboard.copy(getQuickViewLink(selectedActivity))}
              >
                Copy URL
              </Menu.Item>

              <hr />

              <Menu.Item
                leftSection={<Trash weight="fill" size={22} />}
                onClick={() => {
                  setActivityToDelete(selectedActivity?.id || null);
                  setShowDeleteModal(true);
                  trackAction(Actions.SCHEDULED_DELETE);
                }}
              >
                Delete
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
          <ActionIcon
            title="Close"
            onClick={close}
            className={classes.drawerCloseButton}
            size={isMobile ? 'sm' : 'md'}
            ml={12}
          >
            <X size={14} weight="bold" />
          </ActionIcon>
        </>
      }
      withCloseButton={false}
      opened={openedActivityScheduleSummary}
      onClose={() => {
        close();
        if (selectedActivity && setSelectedActivity) {
          setSelectedActivity(null);
        }
      }}
      classNames={{
        content: classes.drawerContent,
        header: classes.drawerHeader,
        body: classes.drawerBody,
      }}
      position={isMobile ? 'bottom' : 'right'}
      overlayProps={{ backgroundOpacity: isMobile ? 0.5 : 0 }}
    >
      <>
        <Group gap={0} wrap="nowrap" align="center" pb={16} ml={-2}>
          <CalendarBlank size={26} className={classes.detailsIcon} />
          <Text className={classes.detailsValue}>
            {selectedActivity?.weekdays && selectedActivity?.weekdays.length > 1
              ? getAbbreviatedDaysString(selectedActivity?.weekdays)
              : `${getWeekdayLabel(selectedActivity?.weekdays?.[0])}s`}
            {selectedActivity?.bookingType === ActivityBookingTypeEnum.SUBSCRIPTION
              ? ''
              : ` | ${getDates(selectedActivity?.minDate, selectedActivity?.maxDate)}`}
          </Text>
        </Group>
        <Group gap={0} wrap="nowrap" align="center" pb={16} ml={-2}>
          {selectedActivity?.isOnline ? (
            <VideoCamera size={26} className={classes.detailsIcon} />
          ) : (
            <MapPin size={26} className={classes.detailsIcon} />
          )}
          <Text className={classes.detailsValue}>
            {selectedActivity?.isOnline
              ? 'Online'
              : buildFullAddressFromLocation(
                  ['addressLine1', 'addressLine2', 'city', 'postCode'],
                  selectedActivity?.location,
                )}
          </Text>
        </Group>
        {showWaitlistBadge && (
          <Button
            className={classes.waitlist}
            component="a"
            href={`/manage-waitlist/${selectedActivity?.id}?supplierId=${supplierId}`}
            onClick={() => {
              trackAction(Actions.SIDESHEET_WAITLIST);
            }}
          >
            <ListPlus size={24} />
            <Flex align="center" ml={4}>
              <Text fw={700}>
                +
                {selectedActivity?.waitlistRequestCount ||
                  data?.activitiesBySupplier?.activities[0].waitlistRequestCount}
              </Text>
              <Text className={classes.waitlistTag}>On Waitlist</Text>
            </Flex>
          </Button>
        )}
        <Flex my="xs" align="center">
          <Switch
            label={'Check In / Check Out'}
            labelPosition="right"
            size="sm"
            classNames={{ track: classes.switchTrack, label: classes.switchLabel }}
            checked={checkInOutTimestamps}
            onChange={(event) => {
              handleRegisterCheckInOutSwitch(event.currentTarget.checked);
              return event.currentTarget.checked
                ? trackAction(Actions.CHECKINOUT_ON)
                : trackAction(Actions.CHECKINOUT_OFF);
            }}
          />
          <PebblePopover
            position="bottom"
            popoverTarget={
              <ActionIcon variant="transparent" ml="xs" title="Check In / Check Out Info">
                <Info size={20} color={theme.colors.blue[8]} />
              </ActionIcon>
            }
            width={{ mobile: 200, desktop: 230 }}
          >
            <Text>
              Turning this on will enable the Check-Out functionality and timestamps for both
              Check-In and Check-Out.
            </Text>
            <Text mt="md">
              Note: If this feature is turned off then no timestamps will be logged.{' '}
            </Text>
          </PebblePopover>
        </Flex>
        {showWarningNotification && isMobile && (
          <Notification
            mt="md"
            classNames={{
              root: classes.notificationRoot,
              body: classes.notificationBody,
            }}
            withCloseButton={false}
          >
            <Flex align="start">
              <div style={{ width: '24px', marginRight: '10px' }}>
                <Warning color={theme.colors.yellow[6]} weight="fill" size={24} />
              </div>
              <Text fw={600} size={'xs'}>
                {' '}
                To view Attendance Overview, please use a desktop or large tablet.
              </Text>
              <ActionIcon
                style={{ width: '24px' }}
                variant="transparent"
                onClick={() => setShowWarningNotification(false)}
              >
                <X color={theme.colors.gray[5]} size={20} weight="bold" />
              </ActionIcon>
            </Flex>
          </Notification>
        )}
        <Space h={12} />
        {loading ? (
          <LoadingCards />
        ) : (
          <>
            {!isSubscription && (
              <>
                <ClassFilters
                  classesDateFilter={classesDateFilter}
                  selectedClass={selectedClass}
                  setSelectedClass={setSelectedClass}
                  activityClasses={selectedActivity?.classes || []}
                  setClassesDateFilter={setClassesDateFilter}
                />
              </>
            )}
            {renderSessionsOverview()}
          </>
        )}
      </>
      <DeleteModal
        afterDelete={() => {}}
        token={token}
        open={showDeleteModal && !!activityToDelete}
        activityId={activityToDelete}
        onClose={() => {
          setActivityToDelete(null);
          setShowDeleteModal(false);
          onDelete();
          close();
        }}
        onCancel={() => {
          setActivityToDelete(null);
          setShowDeleteModal(false);
        }}
      />
    </Drawer>
  );
};

export default ActivityScheduleSummary;
