import { gql } from '@apollo/client';

const CreateActivityAttendeeNote = gql`
  mutation createActivityAttendeeNote($activityId: UUID!, $attendeeId: UUID!, $text: String!) {
    createActivityAttendeeNote(activityId: $activityId, attendeeId: $attendeeId, text: $text) {
      id
      createdAt
    }
  }
`;

export default CreateActivityAttendeeNote;
