import { gql } from '@apollo/client';

export const SupplierLocationsQuery = gql`
  query SupplierLocationsQuery($supplierId: UUID!) {
    supplierLocations(supplierId: $supplierId) {
      supplier {
        id
        name
      }
      locations {
        id
        location {
          id
          name
          addressLine1
          addressLine2
          city
          postCode
          country
          latitude
          longitude
        }
        usage
      }
    }
  }
`;

export default SupplierLocationsQuery;
