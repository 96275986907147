import { useMemo, useState } from 'react';
import { ActivityType } from 'interfaces';
import { ActivityBookingTypeEnum, ActivityStatusEnum } from 'enums';
import { useRouter } from 'next/router';
import {
  buildFullAddressFromLocation,
  getDates,
  getTimes,
  getActivityStatus,
  isActivityInPast,
  getActivityType,
  getSubsTimes,
} from 'components/pages/ScheduledActivities/ScheduledActivities.utils';
import { isNewlyCreated } from 'utils/isNewlyCreated';
import { Actions, trackAction } from 'utils/amplitude';
import { ActionIcon, Group, Menu, Switch, Text, Tooltip, useMantineTheme } from '@mantine/core';
import { Clone, Capacity, Subscriptions, ComboBooking, BlockBooking, SingleSession } from '@icons';
import { getQuickViewLink } from 'utils/getQuickViewLink/getQuickViewLink';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { trackIntercomEvent, IntercomEvents } from '@utils';
import classes from './TableRow.module.scss';
import classNames from 'classnames';
import { BookingStatus } from '../BookingStatus/BookingStatus';
// eslint-disable-next-line import/no-extraneous-dependencies
import QRCode from 'qrcode';
import { useClipboard } from '@mantine/hooks';
import {
  ListPlus,
  Trash,
  Eye,
  PencilSimple,
  EnvelopeSimple,
  QrCode,
  DotsThreeOutlineVertical,
  Copy,
} from '@phosphor-icons/react';

dayjs.extend(advancedFormat);

interface ITableRowProps {
  activity: ActivityType;
  onClone(clonedActivityId: string): void;
  setActivityToDelete(activityId: string): void;
  setShowDeleteModal(e: boolean): void;
  onMenuOpen(activityId: string): void;
  blur: boolean;
  focusedRow: string;
  onStatusChange(activityStatus: ActivityStatusEnum, activityId: string): void;
  onInviteAttendee(mobileSelectedActivityId: string): void;
  handleActivitySelection(activity: ActivityType): void;
}

export const TableRow: React.FC<ITableRowProps> = (props) => {
  const {
    activity,
    onClone,
    setActivityToDelete,
    setShowDeleteModal,
    onMenuOpen,
    blur,
    focusedRow,
    onStatusChange,
    onInviteAttendee,
    handleActivitySelection,
  } = props;
  const router = useRouter();
  const theme = useMantineTheme();
  const [tooltipIsVisible, setTooltipIsVisible] = useState(false);

  const isSubscription = activity?.bookingType === ActivityBookingTypeEnum.SUBSCRIPTION;
  const isInThePast = useMemo(() => isActivityInPast(activity.maxDate), [activity.maxDate]);
  const clipboard = useClipboard({ timeout: 1000 });

  const hasWaitlistRequests = Boolean(
    activity?.waitlistRequestCount && activity?.waitlistRequestCount > 0,
  );

  const formattedNextSession = activity?.minDate
    ? dayjs(activity?.minDate).format('Do MMM YYYY')
    : 'TBC';

  const fullAddress = buildFullAddressFromLocation(
    ['addressLine1', 'addressLine2', 'city', 'postCode'],
    activity?.location,
  );

  const isOnline = activity.isOnline || false; // to be replaced with `activity.isOnline`
  const location = isOnline ? 'Online' : fullAddress;

  let timer: ReturnType<typeof setTimeout>;

  const getTypeIcon = () => {
    const type = getActivityType(activity.bookingType, activity.blockSubtype, activity.sessions);
    switch (type) {
      case 'Block & Individual':
        return (
          <ComboBooking
            role="img"
            aria-label="Block & Individual"
            size={20}
            color={theme.colors.blue[8]}
          />
        );
      case 'Block':
        return (
          <BlockBooking role="img" aria-label="Block" size={20} color={theme.colors.blue[8]} />
        );
      case 'Individual':
        return (
          <SingleSession
            role="img"
            aria-label="Individual"
            size={20}
            color={theme.colors.blue[8]}
          />
        );
      case 'Subscription':
        return (
          <Subscriptions
            role="img"
            aria-label="Subscription"
            size={24}
            color={theme.colors.blue[8]}
          />
        );
    }
  };

  const handleEditClick = () => {
    router.push(`/edit-activity/${activity.id}?supplierId=${router.query.supplierId}`);
  };

  const handleCapacityClick = () => {
    router.push(`/edit-activity/${activity.id}?supplierId=${router.query.supplierId}&tab=capacity`);
  };

  const handleSwitch = (e: boolean) => {
    const status = e ? ActivityStatusEnum.PUBLISHED : ActivityStatusEnum.UNPUBLISHED;
    onStatusChange(status, activity.id);
    trackAction(e ? Actions.SCHEDULED_PUBLISHED : Actions.SCHEDULED_UNPUBLISHED);
    if (status === ActivityStatusEnum.UNPUBLISHED) {
      trackIntercomEvent(IntercomEvents.UNPUBLISH_ACTIVITY);
    }
  };

  const handleMenuDotsHover = () => {
    if (!focusedRow) {
      timer = setTimeout(() => {
        setTooltipIsVisible(true);
      }, 500);
    }
  };

  const handleDownloadQRCode = async (selectedActivity: ActivityType) => {
    try {
      const qrURL = getQuickViewLink(selectedActivity);

      const dataUrl = await QRCode.toDataURL(qrURL, { width: 400 });

      const downloadLink = document.createElement('a');

      downloadLink.href = dataUrl;

      downloadLink.download = `${selectedActivity.name} QRCode.png`;

      document.body.appendChild(downloadLink);

      downloadLink.click();

      document.body.removeChild(downloadLink);
    } catch (error) {
      console.error('Error generating QR code:', error);
    }
  };

  return (
    <tr
      key={activity.id}
      className={classNames({
        [classes.new]: isNewlyCreated(activity.createdAt),
        [classes.opacity]: blur,
      })}
    >
      <td
        role="button"
        className={classes.activityName}
        onClick={() => {
          handleActivitySelection(activity);
          trackAction(Actions.SCHEDULED_NAME);
        }}
      >
        {activity.name}
      </td>

      <td>
        <Group gap={'4px'}>
          <BookingStatus {...activity.bookingsInformation} />
          {hasWaitlistRequests && (
            <Tooltip classNames={{ tooltip: classes.tooltip }} label="Waitlist requests">
              <Group gap="4px" className={classes.waitlist}>
                <ListPlus size={12} />
                <Text size="xs">+{activity?.waitlistRequestCount}</Text>
              </Group>
            </Tooltip>
          )}
        </Group>
      </td>

      <td>
        <Text
          fw="600"
          size="sm"
          className={classNames({ [classes.unpublished]: !getActivityStatus(activity.status) })}
        >
          {isSubscription
            ? `Next: ${formattedNextSession}`
            : getDates(activity?.minDate, activity?.maxDate)}
        </Text>
      </td>
      <td>
        <Text
          fw="600"
          size="sm"
          className={classNames({ [classes.unpublished]: !getActivityStatus(activity.status) })}
        >
          {isSubscription ? getSubsTimes(activity?.schedules) : getTimes(activity?.times)}
        </Text>
      </td>
      <td>
        <Tooltip
          label={getActivityType(activity.bookingType, activity.blockSubtype, activity.sessions)}
          withArrow
          position="bottom"
          openDelay={500}
          classNames={{ tooltip: classes.tooltip }}
        >
          <div
            className={classNames({ [classes.unpublished]: !getActivityStatus(activity.status) })}
          >
            {getTypeIcon()}
          </div>
        </Tooltip>
      </td>
      <td width="30%" data-testid="location-row">
        {' '}
        <Text
          fw="600"
          size="sm"
          className={classNames({ [classes.unpublished]: !getActivityStatus(activity.status) })}
        >
          {location}
        </Text>
      </td>

      <td>
        {(!isInThePast || isSubscription) && (
          <Tooltip
            label={
              activity.status === ActivityStatusEnum.PUBLISHED
                ? 'Unpublish activity'
                : 'Publish activity'
            }
            withArrow
            position="bottom"
            openDelay={500}
            classNames={{ tooltip: classes.tooltip }}
          >
            <div>
              <Switch
                size="md"
                classNames={{ track: classes.switchTrack }}
                checked={getActivityStatus(activity.status)}
                onChange={(event) => handleSwitch(event.currentTarget.checked)}
                data-testid={activity.id}
              />
            </div>
          </Tooltip>
        )}
      </td>
      <td>
        <Menu
          shadow="md"
          width={180}
          classNames={{ dropdown: classes.menuDropdown, item: classes.menuItem }}
          position="bottom-end"
          onOpen={() => {
            setTooltipIsVisible(false);
            onMenuOpen(activity.id);
          }}
          onClose={() => {
            onMenuOpen('');
          }}
        >
          <Tooltip
            label="Options"
            withArrow
            position="bottom"
            classNames={{ tooltip: classes.tooltip }}
            opened={tooltipIsVisible}
            onMouseEnter={handleMenuDotsHover}
            onMouseLeave={() => {
              setTooltipIsVisible(false);
              clearTimeout(timer);
            }}
          >
            <Menu.Target>
              <ActionIcon
                variant="subtle"
                onClick={() => trackAction(Actions.KEBAB)}
                aria-label={`Options for ${activity.name}`}
              >
                <DotsThreeOutlineVertical size={28} weight="fill" color={theme.colors.gray[6]} />
              </ActionIcon>
            </Menu.Target>
          </Tooltip>

          <Menu.Dropdown>
            <Menu.Item
              leftSection={<Capacity size={24} />}
              onClick={() => {
                handleCapacityClick();
                trackAction(Actions.SCHEDULED_SESSIONS);
              }}
            >
              Capacity
            </Menu.Item>
            <Menu.Item
              leftSection={<Clone size={24} />}
              onClick={() => {
                onClone(activity?.id);
                trackAction(Actions.SCHEDULED_CLONE);
                trackIntercomEvent(IntercomEvents.CLONE_ACTIVITY);
              }}
            >
              Clone
            </Menu.Item>
            <Menu.Item
              leftSection={<PencilSimple weight="fill" size={22} />}
              onClick={() => {
                handleEditClick();
                trackAction(Actions.SCHEDULED_EDIT);
              }}
            >
              Edit
            </Menu.Item>
            <Menu.Item
              leftSection={<Eye weight="bold" size={24} />}
              component="a"
              href={getQuickViewLink(activity)}
              target="_blank"
              onClick={() => {
                trackAction(Actions.SCHEDULED_VIEW);
              }}
            >
              View listing
            </Menu.Item>
            <>
              <Menu.Item
                leftSection={<ListPlus size={24} color="white" />}
                component="a"
                href={`/manage-waitlist/${activity?.id}?supplierId=${router.query.supplierId}`}
                onClick={() => trackAction(Actions.VIEW_WAITLIST)}
              >
                Waitlist
              </Menu.Item>
            </>
            {!isInThePast && (
              <Menu.Item
                leftSection={<EnvelopeSimple size={24} weight="fill" />}
                onClick={() => {
                  onInviteAttendee(activity?.id);
                  trackAction(Actions.ENROL_CUSTOMERS_CTA);
                }}
              >
                Invite customers
              </Menu.Item>
            )}
            <Menu.Item
              leftSection={<QrCode weight="fill" size={24} className={classes.menuIcon} />}
              onClick={() => handleDownloadQRCode(activity)}
            >
              Get QR code
            </Menu.Item>
            <Menu.Item
              leftSection={<Copy size={24} weight="bold" className={classes.menuIcon} />}
              onClick={() => clipboard.copy(getQuickViewLink(activity))}
            >
              Copy URL
            </Menu.Item>
            <hr />
            <Menu.Item
              leftSection={<Trash weight="fill" size={22} />}
              onClick={() => {
                setActivityToDelete(activity?.id);
                setShowDeleteModal(true);
                trackAction(Actions.SCHEDULED_DELETE);
              }}
            >
              Delete
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </td>
    </tr>
  );
};
