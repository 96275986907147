import { gql } from '@apollo/client';

const SupplierFutureActivities = gql`
  query SupplierFutureActivities($supplierId: UUID!, $searchTerms: [String], $activityId: UUID) {
    supplierFutureActivities(
      supplierId: $supplierId
      searchTerms: $searchTerms
      activityId: $activityId
    ) {
      activity {
        id
        name
        location {
          addressLine1
          addressLine2
          city
          postCode
        }
        ageRange
        supplier {
          id
        }
      }
      dateRange
      classes {
        name
        id
        dateRange
        sessions {
          date
          products {
            dateRange
            weekdays
            startTime
            endTime
            timeGroup
            spotsLeft
            numberOfSessionsAvailable
            checkoutKey
            checkoutValue
          }
        }
        blocks {
          dateRange
          weekdays
          startTime
          endTime
          timeGroup
          spotsLeft
          numberOfSessionsAvailable
          checkoutKey
          checkoutValue
        }
      }
    }
  }
`;

export default SupplierFutureActivities;
