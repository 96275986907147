import { gql } from '@apollo/client';

const SupplierClassesQuery = gql`
  query SupplierClassesQuery($supplierId: UUID!, $forDate: Date, $includePast: Boolean) {
    supplierClasses(supplierId: $supplierId, forDate: $forDate, includePast: $includePast) {
      sessionsForDate {
        id
        name
        location
        date
        time
        capacity
        bookings
        bookingType
      }
      sessionDates
    }
  }
`;

export default SupplierClassesQuery;
