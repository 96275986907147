import dayjs from 'dayjs';
import { OffDateObject, TInvalidDays } from 'types';
import { ActivityType } from 'interfaces';
import { BlockBookingsTypeEnum, DaysOfWeekEnum } from 'enums';
// import { objectsAreEqual } from 'utils/objects';
import { SERVER_DATE_FORMAT } from './constants';
import {
  ActivityBlock,
  ActivitySession,
  ActivitySessionTimeSlot,
  ActivitySubscriptionSchedule,
  CustomQuestionItem,
  ImageUploadType,
} from 'types';
import { getImageUrl } from '@utils';
import { v4 as uuidv4 } from 'uuid';

export const toImageUploadType = (imageId: string): ImageUploadType => {
  return { imageId, imageUrl: getImageUrl(imageId) };
};

export const getDayOfTheWeek = (date?: string): DaysOfWeekEnum => {
  return dayjs(date).format('dddd').toLowerCase() as DaysOfWeekEnum;
};

export const sortSelectedDaysOfWeek = (weekdays: DaysOfWeekEnum[]): DaysOfWeekEnum[] => {
  const days = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'];

  const sortDays = (a: DaysOfWeekEnum, b: DaysOfWeekEnum) => {
    return days.indexOf(a) - days.indexOf(b);
  };

  return weekdays.sort(sortDays);
};

export const filterBookingInfoFromClonedSessions = (
  sessions: ActivitySession[],
): ActivitySession[] => {
  return sessions.map(({ bookingCount, spotsLeft, ...rest }) => rest);
};

export const getExistingSessions = (
  activity: ActivityType | null,
  isClone = false,
): ActivitySession[] => {
  if (!activity) {
    return [];
  }
  if (activity.classes && activity.classes.length > 0) {
    const allSessions = activity.classes.flatMap((activityClass) => activityClass.sessions);
    return isClone ? filterBookingInfoFromClonedSessions(allSessions) : allSessions;
  }

  return [];
};

export const getTimesFromSessions = (sessions: ActivitySession[]): ActivitySessionTimeSlot[] => {
  const times = sessions
    .map((session) => ({
      dayOfTheWeek: getDayOfTheWeek(session.date),
      startTime: session.startTime,
      endTime: session.endTime,
    }))
    .filter(
      (value, index, self) =>
        index ===
        self.findIndex((t) => t.startTime === value.startTime && t.endTime === value.endTime),
    );

  return times;
};

export const getPriceInPounds = (priceInPence: number) => {
  return priceInPence / 100;
};

export const dayOfWeekIndex = {
  [DaysOfWeekEnum.Sunday]: 0,
  [DaysOfWeekEnum.Monday]: 1,
  [DaysOfWeekEnum.Tuesday]: 2,
  [DaysOfWeekEnum.Wednesday]: 3,
  [DaysOfWeekEnum.Thursday]: 4,
  [DaysOfWeekEnum.Friday]: 5,
  [DaysOfWeekEnum.Saturday]: 6,
};

export const getDatesBetween = (
  [startDate, endDate]: [Date | null, Date | null],
  daysOfWeek: DaysOfWeekEnum[],
) => {
  if (!startDate) {
    return [];
  }

  const selectedDaysIndexes = daysOfWeek.map((day) => dayOfWeekIndex[day]);
  const start = dayjs(startDate);
  const end = endDate ? dayjs(endDate) : start;
  const diffInDays = Math.round(end.diff(start, 'd', true)); // Need to round this to nearest integer to account for Daylight Savings.
  const restOfDates = Array.from({ length: diffInDays }, (v, i) => start.add(i + 1, 'd'));
  const dates = [start, ...restOfDates];
  const filteredDates = dates.filter((date) => selectedDaysIndexes.includes(date.day()));
  return filteredDates;
};

export const filterSessions = (sessions: ActivitySession[]): ActivitySession[] => {
  return sessions
    .filter((session) => !session.isExcluded)
    .map(({ isExcluded, bookingCount, spotsLeft, __typename, dayOfTheWeek, ...rest }) => rest);
};

export const buildSessions = (
  [startDate, endDate]: [Date | null, Date | null],
  sessionTimes: ActivitySessionTimeSlot[][],
  excludedDates: string[],
  daysOfWeek: DaysOfWeekEnum[],
  classId: string,
  isNew: boolean,
): ActivitySession[] => {
  if (!startDate) {
    return [];
  }

  const filteredDates = getDatesBetween([startDate, endDate], daysOfWeek);

  const sessions = sessionTimes.flatMap((sessionTime) => {
    return sessionTime.flatMap((session) => {
      return filteredDates
        .filter((date) => date.day() === dayOfWeekIndex[session.dayOfTheWeek])
        .map((date) => {
          const formattedDate = date.format(SERVER_DATE_FORMAT);

          return {
            ...session,
            date: formattedDate,
            isExcluded: excludedDates.includes(formattedDate),
            capacity: 0,
            id: uuidv4(),
            classId,
            isNew,
          };
        });
    });
  });

  const filteredEmptyTimeSlots = sessions.filter((session) => session.startTime);

  return filteredEmptyTimeSlots;
};

export const getInitialExcludedDates = (
  [startDate, endDate]: [Date | null, Date | null],
  sessions: ActivitySession[],
  daysOfWeek: DaysOfWeekEnum[],
  termOffDates?: string[],
) => {
  if (((!startDate && !endDate) || sessions.length === 0) && !termOffDates) {
    return [];
  }
  if (termOffDates) {
    return termOffDates;
  }
  const dates = getDatesBetween([startDate, endDate], daysOfWeek);
  const excludedDates = dates
    .map((date) => date.format(SERVER_DATE_FORMAT))
    .filter(
      (formattedDate) =>
        !sessions?.some((session) => {
          return session?.date === formattedDate;
        }),
    );
  return excludedDates;
};

export const restoreSubscriptionDaysOfWeek = (
  existingActivity: ActivityType | null,
): DaysOfWeekEnum[] => {
  if (!existingActivity) {
    return [];
  }

  if (existingActivity?.schedules?.length > 0) {
    const subscriptionSelectedDaysOfWeek = Array.from(
      new Set(existingActivity?.schedules?.map((schedule) => schedule.dayOfTheWeek)),
    ) as DaysOfWeekEnum[];
    return sortSelectedDaysOfWeek(subscriptionSelectedDaysOfWeek);
  }

  return [];
};

//Returns unique timeSlots from an array of existing sessions
export const restoreTimesFromSessions = (
  sessions: ActivitySession[],
): ActivitySessionTimeSlot[] => {
  const filteredUniqueTimeslots: ActivitySessionTimeSlot[] = sessions
    //filters out unique sessions object to include just dayOfWeek, startTime and endTime to recreate sessionTimeSlots[]
    .map((session) => ({
      dayOfTheWeek: getDayOfTheWeek(session.date).toUpperCase() as DaysOfWeekEnum,
      startTime: session.startTime,
      endTime: session.endTime,
    }))
    .filter(
      (value, index, self) =>
        index ===
        self.findIndex(
          (t) =>
            t.dayOfTheWeek === value.dayOfTheWeek &&
            t.startTime === value.startTime &&
            t.endTime === value.endTime,
        ),
    );
  return filteredUniqueTimeslots;
};

// Returns session or empty session based on selected days of week array
export const getSessionTimeslotForDayOfWeek = (
  timeslots: ActivitySessionTimeSlot[],
  dayOfTheWeek: DaysOfWeekEnum,
): ActivitySessionTimeSlot => {
  const sessionTimeslot = timeslots.find((timeslot) => timeslot.dayOfTheWeek === dayOfTheWeek);

  if (sessionTimeslot) {
    return sessionTimeslot;
  } else {
    return {
      dayOfTheWeek,
      startTime: '',
      endTime: '',
    };
  }
};

export const insertEmptyTimeslotSessions = (
  activitySessionTimeslots: ActivitySessionTimeSlot[][],
  daysOfWeek: DaysOfWeekEnum[],
): ActivitySessionTimeSlot[][] => {
  // Includes empty timeslots
  const updateActivitySessionTimeslots = activitySessionTimeslots.map((timeslots) =>
    daysOfWeek.map((dayOfWeek) => getSessionTimeslotForDayOfWeek(timeslots, dayOfWeek)),
  );

  return updateActivitySessionTimeslots;
};

export const groupRestoredTimesByTimeSlots = (
  filteredUniqueTimeslots: ActivitySessionTimeSlot[],
): ActivitySessionTimeSlot[][] => {
  const result: ActivitySessionTimeSlot[][] = [[]];
  const groupsByWeekday: Record<string, ActivitySessionTimeSlot[]> = {};

  for (const timeSlot of filteredUniqueTimeslots) {
    const { dayOfTheWeek } = timeSlot;

    if (!groupsByWeekday[dayOfTheWeek]) {
      groupsByWeekday[dayOfTheWeek] = [timeSlot];
    } else {
      groupsByWeekday[dayOfTheWeek].push(timeSlot);
    }
  }

  for (const dayOfWeek in groupsByWeekday) {
    const groupsByTimeSlot = groupsByWeekday[dayOfWeek];

    for (let i = 0; i < groupsByTimeSlot.length; i++) {
      if (!result[i]) {
        result[i] = [];
      }

      result[i].push(groupsByTimeSlot[i]);
    }
  }

  return insertEmptyTimeslotSessions(result, Object.keys(groupsByWeekday) as DaysOfWeekEnum[]);
};

export const restoreSessionTimeslots = (
  sessions: ActivitySession[],
): ActivitySessionTimeSlot[][] => {
  return groupRestoredTimesByTimeSlots(restoreTimesFromSessions(sessions));
};

//// validation functions
export const hasIncorrectTimeslots = (sessionTimeSlots: ActivitySessionTimeSlot[][]) => {
  const result: boolean[] = [];

  sessionTimeSlots.forEach((sessionTimeslot) => {
    const isEmptyTimeslot = sessionTimeslot.some((session) => session.startTime && session.endTime);

    result.push(isEmptyTimeslot);
  });

  return result.some((item) => item === false);
};

export const incorrectTimeslot = (sessionTimeSlots: ActivitySessionTimeSlot[][]): number => {
  const result: boolean[] = [];

  sessionTimeSlots.forEach((sessionTimeslot) => {
    const isEmptyTimeslot = sessionTimeslot.some((session) => session.startTime && session.endTime);

    result.push(isEmptyTimeslot);
  });

  return result.findIndex((item) => item === false);
};

export const hasWeekdayWithEmptyTimeslots = (
  daysOfWeek: DaysOfWeekEnum[],
  sessionTimeSlots: ActivitySessionTimeSlot[][],
): DaysOfWeekEnum[] => {
  const weekdayWithNoTimeslots: DaysOfWeekEnum[] = [];
  daysOfWeek.map((dayOfWeek) => {
    const hasError = sessionTimeSlots
      .flat()
      .filter((timeslot) => timeslot.dayOfTheWeek === dayOfWeek)
      .every((timeslot) => !timeslot.startTime && !timeslot.endTime);
    if (hasError) {
      weekdayWithNoTimeslots.push(dayOfWeek);
    }
  });

  return weekdayWithNoTimeslots;
};

export const duplicateTimeSlots = (
  daysOfWeek: DaysOfWeekEnum[],
  sessionTimeSlots: ActivitySessionTimeSlot[][],
) => {
  const duplicates: DaysOfWeekEnum[] = [];
  daysOfWeek.map((dayOfWeek) => {
    const hasError = sessionTimeSlots.flat().filter((timeslot) => {
      return timeslot.dayOfTheWeek === dayOfWeek;
    });

    const findDuplicates: ActivitySessionTimeSlot[] = [];
    for (const item of hasError) {
      const isDuplicate = findDuplicates.find(
        (obj) =>
          item.startTime !== '' &&
          item.endTime !== '' &&
          obj.startTime === item.startTime &&
          obj.endTime === item.endTime,
      );

      if (!isDuplicate) {
        findDuplicates.push(item);
      }
    }

    if (hasError.length !== findDuplicates.length) {
      duplicates.push(dayOfWeek);
    }
  });

  return duplicates;
};

export const validStartAndEndTimes = (sessionTimeSlots: ActivitySessionTimeSlot[][]) => {
  const result: boolean[] = [];

  sessionTimeSlots.forEach((timeslot) => {
    const checkTimes = timeslot.every((session) => {
      if (session.startTime && session.endTime) {
        return session.startTime <= session.endTime;
      } else {
        return true;
      }
    });

    result.push(checkTimes);
  });

  return result.every((item) => item === true);
};

export const invalidStartAndEndTimesIndex = (
  sessionTimeSlots: ActivitySessionTimeSlot[][],
): TInvalidDays | null => {
  const VALID_TIMESLOTS: TInvalidDays = {
    [DaysOfWeekEnum.Monday]: [],
    [DaysOfWeekEnum.Tuesday]: [],
    [DaysOfWeekEnum.Wednesday]: [],
    [DaysOfWeekEnum.Thursday]: [],
    [DaysOfWeekEnum.Friday]: [],
    [DaysOfWeekEnum.Saturday]: [],
    [DaysOfWeekEnum.Sunday]: [],
  };

  const days: Record<DaysOfWeekEnum, number[]> | null = VALID_TIMESLOTS;
  let hasInvalidTimeslot = false;

  sessionTimeSlots.forEach((sessionTimeslot, timeslotIndex) => {
    sessionTimeslot.forEach((session) => {
      const weekday = session.dayOfTheWeek;
      const index = timeslotIndex;

      if (!session.startTime || !session.endTime) return;

      const startTime = dayjs(session.startTime, 'HH:mm').toDate();

      const endTime = dayjs(session.endTime, 'HH:mm').toDate();

      const isValid = session.endTime && session.startTime && startTime <= endTime;

      if (!isValid && days) {
        days[weekday].push(index);
        hasInvalidTimeslot = true;
      }
    });
  });

  if (!hasInvalidTimeslot) return null;

  return days;
};

export const filterSchedules = (
  schedules: ActivitySubscriptionSchedule[],
  removeId = false,
): ActivitySubscriptionSchedule[] => {
  if (!schedules) return [];

  if (removeId) {
    return schedules.map(({ activeSubs, __typename, id, ...rest }) => rest);
  } else {
    return schedules.map(({ activeSubs, __typename, ...rest }) => rest);
  }
};

export const buildSubscriptionSchedules = (
  subscriptionTimeSlots: ActivitySessionTimeSlot[][],
): ActivitySubscriptionSchedule[] => {
  const subscriptionSchedules = subscriptionTimeSlots.flatMap((subscriptionTimeSlot) => {
    return subscriptionTimeSlot.flatMap(({ order, ...scheduleData }) => {
      return { ...scheduleData, capacity: 0 };
    });
  });

  const filteredEmptySchedules = subscriptionSchedules.filter((schedule) => schedule.startTime);
  return filteredEmptySchedules;
};

export const restoreTimesFromSchedules = (
  schedules: ActivitySubscriptionSchedule[],
): ActivitySessionTimeSlot[] => {
  const filteredScheduleTimeslots: ActivitySessionTimeSlot[] = schedules.map((schedule) => ({
    dayOfTheWeek: schedule.dayOfTheWeek,
    startTime: schedule.startTime,
    endTime: schedule.endTime,
  }));
  return filteredScheduleTimeslots;
};

export const restoreScheduleTimeslots = (
  existingSchedules?: ActivitySubscriptionSchedule[],
): ActivitySessionTimeSlot[][] => {
  if (existingSchedules) {
    return groupRestoredTimesByTimeSlots(restoreTimesFromSchedules(existingSchedules));
  }
  return [[]];
};

export const restoreOffDates = (offDates?: OffDateObject[]) => {
  if (offDates) {
    return offDates.map(({ date }) => new Date(date));
  }
  return [];
};

export const restoreActivityQuestions = (
  questionBankList?: CustomQuestionItem[],
  prevQuestions?: CustomQuestionItem[],
) => {
  const previousActivityQuestions: CustomQuestionItem[] | null =
    prevQuestions && prevQuestions.length > 0 ? prevQuestions : null;

  const defaultQuestions = questionBankList
    ? questionBankList.filter((question) => question.isDefault)
    : [];
  if (previousActivityQuestions) {
    return previousActivityQuestions;
  } else {
    return defaultQuestions;
  }
};

export const groupSessionsByDay = (activitySessions: ActivitySession[]): ActivitySession[][] => {
  const sessionsByDay = activitySessions.reduce((acc, value) => {
    const sessionDayOfWeek = value.dayOfTheWeek || getDayOfTheWeek(value.date).toUpperCase();
    if (!sessionDayOfWeek) {
      return acc;
    }
    // eslint-disable-next-line
    // @ts-ignore
    const existing = acc[sessionDayOfWeek] || [];
    return {
      ...acc,
      [sessionDayOfWeek]: [...existing, value],
    };
  }, {});

  return Object.values(sessionsByDay);
};

export const getFilteredBlocks = (
  sessionTimeSlots: ActivitySessionTimeSlot[][],
  sessions: ActivitySession[],
  blockSubtype: string,
  classId: string,
  isNew: boolean,
): ActivityBlock[] => {
  const sessionGroupedByTimeSlot: ActivitySession[][] = sessionTimeSlots.map((timeSlot) => {
    return sessions.filter((session) =>
      timeSlot.some((slot) => {
        const sessionDayOfWeek =
          session.dayOfTheWeek || getDayOfTheWeek(session.date).toUpperCase();
        return (
          slot.dayOfTheWeek === sessionDayOfWeek &&
          slot.startTime === session.startTime &&
          slot.endTime === session.endTime
        );
      }),
    );
  });
  return blockSubtype === BlockBookingsTypeEnum.ALL_DAYS
    ? sessionGroupedByTimeSlot.map((groupedSessions) => ({
        id: uuidv4(),
        sessions: filterSessions(groupedSessions).map((session) => session.id),
        classId,
        isNew,
      }))
    : sessionGroupedByTimeSlot.flatMap(groupSessionsByDay).map((groupedSessions) => {
        return {
          id: uuidv4(),
          sessions: filterSessions(groupedSessions).map((session) => session.id),
          classId,
          isNew,
        };
      });
};
