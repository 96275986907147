import { Dispatch, FC, SetStateAction, useState } from 'react';
import Image from 'next/legacy/image';
import { Center, Skeleton, Stack, Table, Text } from '@mantine/core';
import { ActivityType } from 'interfaces';
import { ActivityStatusEnum } from 'enums';
import { DeleteModal } from 'components/pages/ScheduledActivities/components/DeleteModal';
import { TableRow } from './TableRow/TableRow';
import classes from './TableView.module.scss';
import classNames from 'classnames';
interface ITableViewProps {
  loading: boolean;
  activitiesBySupplier: ActivityType[];
  onDelete(): void;
  onClone(clonedActivityId: string): void;
  onStatusChange(activityStatus: ActivityStatusEnum, activityId: string): void;
  token: string;
  onInviteAttendee(mobileSelectedActivityId: string): void;
  handleActivitySelection(activity: ActivityType): void;
  setActivityToDelete: Dispatch<SetStateAction<string | null>>;
  activityToDelete: string | null;
  setShowDeleteModal: Dispatch<SetStateAction<boolean>>;
  showDeleteModal: boolean;
}

export const TableView: FC<ITableViewProps> = (props) => {
  const {
    loading,
    activitiesBySupplier,
    onDelete,
    onClone,
    onStatusChange,
    token,
    onInviteAttendee,
    handleActivitySelection,
    showDeleteModal,
    setShowDeleteModal,
    activityToDelete,
    setActivityToDelete,
  } = props;
  const [focusedRow, setFocusedRow] = useState('');

  return (
    <>
      <Table
        aria-label="Activities"
        className={classNames(classes.table, {
          [classes.tdNoResult]: activitiesBySupplier?.length === 0,
        })}
      >
        <thead>
          <tr>
            <th>Activity</th>
            <th>Spots Filled</th>
            <th>Date(s)</th>
            <th>Time(s)</th>
            <th>Type</th>
            <th>Location</th>
            <th colSpan={2}></th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr>
              <td className="tdNoResult" colSpan={8}>
                <Skeleton height={24} mb="sm" width="100%" radius="xs" />
                <Skeleton height={24} mb="sm" width="100%" radius="xs" />
                <Skeleton height={24} mb="sm" width="100%" radius="xs" />
                <Skeleton height={24} mb="sm" width="100%" radius="xs" />
                <Skeleton height={24} mb="sm" width="100%" radius="xs" />
                <Skeleton height={24} mb="sm" width="100%" radius="xs" />
                <Skeleton height={24} mb="sm" width="100%" radius="xs" />
              </td>
            </tr>
          ) : activitiesBySupplier?.length ? (
            activitiesBySupplier?.map((activity: ActivityType) => (
              <TableRow
                key={activity.id}
                activity={activity}
                onClone={onClone}
                setActivityToDelete={setActivityToDelete}
                setShowDeleteModal={setShowDeleteModal}
                onMenuOpen={(activityId) => setFocusedRow(activityId)}
                focusedRow={focusedRow}
                blur={Boolean(focusedRow && focusedRow !== activity.id)}
                onStatusChange={onStatusChange}
                onInviteAttendee={onInviteAttendee}
                handleActivitySelection={handleActivitySelection}
              />
            ))
          ) : (
            <tr>
              <td className="tdNoResult" colSpan={5}>
                <Center className={classes.noResult}>
                  <Stack>
                    <Image src="/cubes.png" alt="Stacked cubes" width={140} height={78} />
                    <Text
                      style={{
                        textAlign: 'center',
                      }}
                    >
                      You don't have any scheduled activities yet.
                      <br />
                      Once you do, they will appear here.
                    </Text>
                  </Stack>
                </Center>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      <DeleteModal
        afterDelete={() => {}}
        token={token}
        open={showDeleteModal && !!activityToDelete}
        activityId={activityToDelete}
        onClose={() => {
          setActivityToDelete(null);
          setShowDeleteModal(false);
          onDelete();
        }}
        onCancel={() => {
          setActivityToDelete(null);
          setShowDeleteModal(false);
        }}
      />
    </>
  );
};
