import { gql } from '@apollo/client';

export const RefundReceiptQuery = gql`
  query getRefundReceipt($supplierId: UUID!, $bookingSessionIds: [UUID]!, $refundAmount: Int) {
    getRefundReceipt(
      supplierId: $supplierId
      bookingSessionIds: $bookingSessionIds
      refundAmount: $refundAmount
    ) {
      booking {
        id
        originalAmount
        finalAmount
        discount
        refunds {
          id
        }
        refundsAmount
        isRefundable
        refundAllowance
        activity {
          name
          location {
            addressLine1
            addressLine2
            city
            country
            postCode
          }
        }
        bookedTickets {
          ticket {
            id
            name
            description
            ticketType
            price
            pricingPolicy
          }
          numberOfSessions
          ticketNameAtBooking
          ticketPriceAtBooking
          addonsByAttendee {
            attendee {
              id
              fullName
            }
            bookingAddons {
              name
              price
              quantity
            }
            sessionAddons {
              name
              price
              quantity
            }
          }
          attendees {
            id
            fullName
            guest {
              id
              fullName
            }
            guardian {
              id
              fullName
            }
          }
        }
        discount
        promotion {
          code
          pebblePaysForPromotions
        }
      }
      refundableSessions {
        id
        sessionStart
        sessionEnd
      }
      refundAmount
    }
  }
`;

export default RefundReceiptQuery;
