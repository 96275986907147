import {
  Badge,
  Box,
  Flex,
  Spoiler,
  Stack,
  Text,
  Title,
  Divider,
  useMantineTheme,
} from '@mantine/core';
import classes from './ClassCapacity.module.scss';
import { useLazyQuery } from '@apollo/client';
import SupplierClassCapacity from 'graphql/queries/supplier-class-capacity';
import React, { useEffect, useState } from 'react';
import { showErrorMessage } from 'utils/showErrorMessage/showErrorMessage';
import { useRouter } from 'next/router';
import PebbleButton from 'components/common/PebbleButton/PebbleButton';
import { Actions, trackAction } from 'utils/amplitude';
import { useMediaQuery } from '@mantine/hooks';
import { UsersThree } from '@phosphor-icons/react';

interface ClassCapacityProps {
  token: string;
  supplierId: string;
}

interface ClassCapacityData {
  name: string;
  date: string;
  time: string;
  capacity: number;
  bookings: number;
  percentBooked: number;
  activityId: string;
}

interface SupplierClassCapacityData {
  supplierClassCapacity: {
    noCapacity?: ClassCapacityData[];
    soldOut?: ClassCapacityData[];
    nearCapacity?: ClassCapacityData[];
  };
}

enum CapacityEvent {
  DASH_NO_CAPACITY = 'dash_no_capacity',
  DASH_SOLD_OUT = 'dash_sold_out',
  DASH_NEAR_CAPACITY = 'dash_near_capacity',
}

const ClassInformation: React.FC<{
  classInfo: ClassCapacityData;
  capacityEvent: CapacityEvent;
  showCapacity?: boolean;
}> = ({
  classInfo: { name, date, time, capacity, bookings, percentBooked, activityId },
  capacityEvent,
  showCapacity = false,
}) => {
  const theme = useMantineTheme();
  const router = useRouter();
  const { supplierId } = router.query;

  const getBadgeColor = () => {
    if (percentBooked >= 70 && percentBooked < 80)
      return { backgroundColor: 'rgba(255, 249, 219, 1)', borderColor: 'rgb(250, 176, 5)' };

    if (percentBooked >= 80 && percentBooked < 90)
      return { backgroundColor: 'rgba(255, 244, 230, 1)', borderColor: '#F8840D' };

    if (percentBooked >= 90)
      return { backgroundColor: 'rgba(255, 245, 245, 1)', borderColor: '#D93E1C' };
  };

  const handleEvents = () => {
    switch (capacityEvent) {
      case CapacityEvent.DASH_NO_CAPACITY: {
        return trackAction(Actions.DASH_NO_CAPACITY);
      }
      case CapacityEvent.DASH_SOLD_OUT: {
        return trackAction(Actions.DASH_SOLD_OUT);
      }
      default: {
        return trackAction(Actions.DASH_NEAR_CAPACITY);
      }
    }
  };

  return (
    <>
      <Flex gap={8} align={'center'} mt={theme.spacing.sm}>
        <div className={classes.classInfo}>
          <Text
            className={classes.sessionName}
            title={name}
            style={{
              fontWeight: 600,
            }}
          >
            {name}
          </Text>
          <Text
            size="xs"
            c={theme.colors.gray[6]}
            style={{
              fontWeight: 600,
            }}
          >
            {date}, {time}
          </Text>
        </div>
        {showCapacity && (
          <div className={classes.capacityBadge}>
            <Badge
              size="lg"
              style={getBadgeColor()}
              classNames={{ root: classes.badgeLabel }}
              leftSection={<UsersThree size={22} color={theme.colors.blue[8]} />}
            >
              {bookings} / {capacity}
            </Badge>
          </div>
        )}
        <div className={classes.updateCapacityBtn}>
          <PebbleButton
            className={classes.capacityUpdate}
            radius="lg"
            size="xs"
            onClick={() => {
              handleEvents();
              router.push(`/edit-activity/${activityId}?supplierId=${supplierId}&tab=capacity`);
            }}
          >
            Update
          </PebbleButton>
        </div>
      </Flex>
      <Divider size="xs" />
    </>
  );
};

const defaultData = {
  supplierClassCapacity: {},
};

const ClassCapacity: React.FC<ClassCapacityProps> = ({ token, supplierId }) => {
  const [fetchedData, setFetchedData] = useState<SupplierClassCapacityData>(defaultData);
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`, true);

  const [getData, { loading }] = useLazyQuery<SupplierClassCapacityData>(SupplierClassCapacity, {
    context: {
      headers: {
        Authorization: `${token}`,
      },
    },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data) {
        setFetchedData(data);
      }
    },
    onError: (error) => {
      console.log(error);
      showErrorMessage(error);
    },
  });

  useEffect(() => {
    if (supplierId) {
      setFetchedData(defaultData);
      getData({
        variables: {
          supplierId,
        },
      });
    }
  }, [supplierId, getData]);

  if (loading) {
    return <div>Loading...</div>;
  }

  const {
    supplierClassCapacity: { noCapacity = [], soldOut = [], nearCapacity = [] },
  } = fetchedData as SupplierClassCapacityData;

  return (
    <>
      {noCapacity.length === 0 && soldOut.length === 0 && nearCapacity.length === 0 ? (
        <Text>All your upcoming classes have capacity for further bookings.</Text>
      ) : (
        <>
          <Text>
            Some of your classes are sold out or approaching capacity. You can add more capacity
            below
          </Text>
          <Box hidden={noCapacity.length === 0}>
            <Title order={6} mt="xl" mb="xs">
              No Capacity Added
            </Title>
            <Spoiler
              maxHeight={250}
              showLabel="View more"
              hideLabel="View less"
              classNames={{
                control: classes.spoilerControl,
              }}
            >
              <Stack>
                {noCapacity.map((classInfo) => (
                  <ClassInformation
                    classInfo={classInfo}
                    key={`${classInfo.name}-${classInfo.date}-${classInfo.time}`}
                    capacityEvent={CapacityEvent.DASH_NO_CAPACITY}
                  />
                ))}
              </Stack>
            </Spoiler>
          </Box>
          <Box hidden={soldOut.length === 0}>
            <Title order={6} mt="xl" mb="xs">
              Sold Out
            </Title>
            <Spoiler
              maxHeight={250}
              showLabel="View more"
              hideLabel="View less"
              classNames={{
                control: classes.spoilerControl,
              }}
            >
              <Stack>
                {soldOut.map((classInfo) => (
                  <ClassInformation
                    classInfo={classInfo}
                    key={`${classInfo.name}-${classInfo.date}-${classInfo.time}`}
                    capacityEvent={CapacityEvent.DASH_SOLD_OUT}
                  />
                ))}
              </Stack>
            </Spoiler>
          </Box>
          <Box hidden={nearCapacity.length === 0}>
            <Title order={6} mt="xl" mb="xs">
              Near Capacity
            </Title>
            <Spoiler
              maxHeight={isMobile ? 260 : 230}
              showLabel="View more"
              hideLabel="View less"
              classNames={{
                control: classes.spoilerControl,
              }}
            >
              <Stack>
                {nearCapacity.map((classInfo) => (
                  <ClassInformation
                    classInfo={classInfo}
                    showCapacity={true}
                    key={`${classInfo.name}-${classInfo.date}-${classInfo.time}`}
                    capacityEvent={CapacityEvent.DASH_NEAR_CAPACITY}
                  />
                ))}
              </Stack>
            </Spoiler>
          </Box>
        </>
      )}
    </>
  );
};

export default ClassCapacity;
