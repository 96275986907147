import { FC, useState } from 'react';
import {
  ActionIcon,
  Box,
  Card,
  Collapse,
  Divider,
  Grid,
  Group,
  Stack,
  Switch,
  Text,
  useMantineTheme,
} from '@mantine/core';
import {
  CalendarBlank,
  CaretDown,
  Clock,
  DotsThreeOutlineVertical,
  ListPlus,
  MapPin,
} from '@phosphor-icons/react';
import {
  getActivityStatus,
  getActivityType,
  getSubsTimes,
  isActivityInPast,
} from 'components/pages/ScheduledActivities/ScheduledActivities.utils';
import { Clone, ComboBooking, BlockBooking, SingleSession, Subscriptions } from '@icons';
import { Actions, trackAction } from 'utils/amplitude';
import { ActivitySession, ActivitySubscriptionSchedule } from 'types';
import { BookingsInformation } from 'interfaces';
import { ActivityStatusEnum, ActivityBookingTypeEnum } from 'enums';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { IntercomEvents, trackIntercomEvent } from '@utils';
import classes from './ActivityCard.module.scss';
import classNames from 'classnames';
import { BookingStatus } from '../TableView/BookingStatus/BookingStatus';

dayjs.extend(advancedFormat);

interface IActivityCardProps {
  id: string;
  name: string;
  status: ActivityStatusEnum;
  maxDate?: string;
  minDate?: string;
  dates: string;
  address: string | null;
  times: string;
  bookingType: string;
  blockSubtype: string | null;
  activitySessions: ActivitySession[];
  isNewlyCreated: boolean;
  onMobileDrawerOpenClick(activityId: string): void;
  onCloneClick(clonedActivityId: string): void;
  onStatusChange(activityStatus: ActivityStatusEnum, activityId: string): void;
  subsSchedules: ActivitySubscriptionSchedule[];
  isOnline: boolean;
  bookingsInformation: BookingsInformation;
  handleActivityNameClick(activityId: string): void;
  waitlistRequestCount?: number | null;
}

export const ActivityCard: FC<IActivityCardProps> = (props) => {
  const {
    dates,
    address,
    times,
    bookingType,
    blockSubtype,
    activitySessions,
    isNewlyCreated,
    id,
    name,
    status,
    maxDate,
    onMobileDrawerOpenClick,
    onCloneClick,
    onStatusChange,
    subsSchedules,
    minDate,
    isOnline,
    bookingsInformation,
    waitlistRequestCount,
    handleActivityNameClick,
  } = props;
  const theme = useMantineTheme();
  const [opened, setOpen] = useState(false);

  const isSubscription = bookingType === ActivityBookingTypeEnum.SUBSCRIPTION;

  const formattedNextSession = dayjs(minDate).format('Do MMM YYYY');

  const getTypeIcon = () => {
    const type = getActivityType(bookingType, blockSubtype, activitySessions);
    switch (type) {
      case 'Block & Individual':
        return (
          <ComboBooking
            role="img"
            title="Block & Individual"
            size={20}
            color={theme.colors.blue[8]}
            className={classes.activityTypeIcon}
          />
        );
      case 'Block':
        return (
          <BlockBooking
            role="img"
            title="Block"
            size={20}
            color={theme.colors.blue[8]}
            className={classes.activityTypeIcon}
          />
        );
      case 'Individual':
        return (
          <SingleSession
            role="img"
            title="Individual"
            size={20}
            color={theme.colors.blue[8]}
            className={classes.activityTypeIcon}
          />
        );
      case 'Subscription':
        return (
          <Subscriptions
            role="img"
            title="Subscription"
            size={26}
            color={theme.colors.blue[8]}
            className={classes.subsTypeIcon}
          />
        );
    }
  };

  const handleSwitch = (e: boolean) => {
    const activityStatus = e ? ActivityStatusEnum.PUBLISHED : ActivityStatusEnum.UNPUBLISHED;
    onStatusChange(activityStatus, id);
    trackAction(e ? Actions.SCHEDULED_PUBLISHED : Actions.SCHEDULED_UNPUBLISHED);
    if (activityStatus === ActivityStatusEnum.UNPUBLISHED) {
      trackIntercomEvent(IntercomEvents.UNPUBLISH_ACTIVITY);
    }
  };

  const onOptionsClick = () => {
    onMobileDrawerOpenClick(id);
  };

  return (
    <Card
      shadow="sm"
      radius="xs"
      className={classNames(classes.card, {
        [classes.new]: isNewlyCreated,
      })}
    >
      <Grid gutter={{ base: 30 }}>
        <Grid.Col
          role="button"
          span="auto"
          onClick={() => {
            handleActivityNameClick(id);
            trackAction(Actions.SCHEDULED_NAME);
          }}
        >
          <Text className={classes.title}>{name}</Text>
        </Grid.Col>
        <Grid.Col span="content">
          <ActionIcon
            className={classes.collapseButton}
            variant="transparent"
            onClick={() => setOpen((o) => !o)}
          >
            <CaretDown
              weight="bold"
              color={theme.colors.blue[8]}
              size={16}
              className={classNames({ [classes.open]: opened })}
            />
          </ActionIcon>
        </Grid.Col>
      </Grid>
      <Group
        gap={0}
        wrap="nowrap"
        align="center"
        pt="sm"
        pb={10}
        className={classNames({ [classes.unpublished]: !getActivityStatus(status) })}
      >
        <CalendarBlank size={22} className={classes.detailsIcon} />
        <Text className={classes.detailsValue}>
          {isSubscription ? `Next: ${formattedNextSession}` : dates}
        </Text>
      </Group>
      <Group
        gap={0}
        wrap="nowrap"
        align="center"
        pb={10}
        className={classNames({ [classes.unpublished]: !getActivityStatus(status) })}
      >
        <MapPin size={23} className={classes.detailsIcon} />
        <Text className={classes.detailsValue}>{isOnline ? 'Online' : address}</Text>
      </Group>
      <Group
        gap={0}
        wrap="nowrap"
        align="center"
        pb={0}
        className={classNames({ [classes.unpublished]: !getActivityStatus(status) })}
      >
        <Clock size={24} className={classes.detailsIcon} />

        <Text className={classes.detailsValue}>
          {' '}
          {isSubscription ? getSubsTimes(subsSchedules) : times}
        </Text>
      </Group>
      <Collapse in={opened}>
        <Group
          gap={0}
          wrap="nowrap"
          pt={12}
          align="center"
          className={classNames({ [classes.unpublished]: !getActivityStatus(status) })}
        >
          <Box className={classes.detailsIcon}>{getTypeIcon()}</Box>
          <Text className={classes.detailsValue}>
            {getActivityType(bookingType, blockSubtype, activitySessions)}
          </Text>
        </Group>
        <Group
          gap={0}
          wrap="nowrap"
          pt={12}
          align="center"
          className={classNames({ [classes.unpublished]: !getActivityStatus(status) })}
        >
          <Box className={classes.detailsIcon}>
            <ListPlus size={24} />
          </Box>
          <Group gap={'xs'}>
            <BookingStatus {...bookingsInformation} />
            {waitlistRequestCount && waitlistRequestCount > 0 && (
              <Box className={classes.waitlist}>+{waitlistRequestCount}</Box>
            )}
          </Group>
        </Group>
        <Divider my="sm" />
        <Grid columns={12}>
          <Grid.Col span={4}>
            <Stack
              gap={0}
              align="start"
              onClick={() => {
                onCloneClick(id);
                trackAction(Actions.SCHEDULED_CLONE);
                trackIntercomEvent(IntercomEvents.CLONE_ACTIVITY);
              }}
              className={classNames(classes.cloneButton, classes.iconButtons)}
            >
              <div className={classes.stackContainer}>
                <Clone size={30} />
                Clone
              </div>
            </Stack>
          </Grid.Col>
          <Grid.Col span={4}>
            {(!isActivityInPast(maxDate) || isSubscription) && (
              <Stack
                gap={0}
                align="center"
                className={classNames(classes.switch, classes.iconButtons)}
              >
                <Switch
                  size="lg"
                  classNames={{ track: classes.switchTrack }}
                  checked={getActivityStatus(status)}
                  onChange={(event) => handleSwitch(event.currentTarget.checked)}
                />
                <Text p={0} size="xs">
                  {getActivityStatus(status) ? 'Unpublish' : 'Publish'}
                </Text>
              </Stack>
            )}
          </Grid.Col>

          <Grid.Col span={4}>
            <Stack
              gap={0}
              align="end"
              onClick={() => {
                onOptionsClick();
                trackAction(Actions.SCHEDULED_DELETE);
              }}
              className={classes.iconButtons}
            >
              <div className={classes.stackContainer}>
                <DotsThreeOutlineVertical
                  onClick={() => trackAction(Actions.KEBAB)}
                  size={30}
                  data-testid="test-id-kebabMenu"
                  className={classes.rotate90deg}
                  weight="fill"
                />
                Options
              </div>
            </Stack>
          </Grid.Col>
        </Grid>
      </Collapse>
    </Card>
  );
};
